.profile-layout {
  display: flex;
  align-items: start;
  text-align: left;
  margin-top: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 10px;
  gap: 20px;
  align-items: center;
}

.profile-image {
  border-radius: 15px;
  height: 205px;
  // object-fit: cover;
  width: 204px;
  position: relative;
}
.cardOverlayPost {
  position: absolute;
  border-radius: 15px;
  visibility: hidden;
  height: 150px;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 0px;
  font-size: 12px;
  color: white;
  top: 0px;
  .btn {
    border-color: white;
    color: white;
    border-radius: 15px;
    padding: 0px 12px;
    font-size: 12px;
  }
}
.imgcontainer:hover .cardOverlayPost {
  visibility: visible;
  transition: 3s;
  transition: 0.2s;
}

.comment {
  height: auto;
  overflow: auto;
  padding-top: 10px;
  text-align: left;
}
.content-col {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  .profile {
    margin-bottom: 16px;
    gap: 16px;
  }
}

.avatar {
  display: flex;
  justify-content: center;
}

.postBody {
  list-style-position: inside;
}

.report-profile-image {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-end;
  border-radius: 15px;
  height: 205px;
  width: 330px;
  position: relative;
  border: 1px solid #f8f8f8;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  position: relative;
  .postImageTitle {
    font-size: 24px;
    color: white;
    text-align: center;
    text-decoration: underline;
  }
  .postImageFooter {
    font-size: 14px;
    color: white;
    background-color: #16114d;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}
