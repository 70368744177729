.personalDtlsSection{
    // padding-top: 20px;
    // padding-bottom: 10px;
    // padding-left: 10px;
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100vh;
    &-cardone{
        flex: 1;
        &__details{
            color: #0081FC;
            font-size: 24px;
            margin-bottom: 0px;
            .details-user{
                cursor: pointer;
                vertical-align: middle;
            }
            .details-username{
                font-size: 16px;
                vertical-align: middle;
                font-weight: 600;
            }
        }
        .prospectImage{
            text-align: center;
        }
        &__heading{
            font-weight: 600;
        }
        &__personaldtls{
            display: flex;
              flex-direction: column;
              gap: 20px;
              margin-top: 10px;
              margin-bottom: 20px;
              .personaldtls-text{
                color: #313131;
                font-size: 13px;
              }
        }
        &__tagbox{
            flex-wrap: wrap;
            display: inline-flex;
            .tagbox-tag{
                background-color: #f2f9ff;
                color: #349afd;
                text-align: left;
                font-size: 10px;
                padding: 2px 10px;
                margin-right: 10px;
                margin-top: 5px;
                margin-bottom: 10px;
                border: 1px solid #f2f9ff;
                border-radius: 19px;
            }
        }
    }
    &-cardthree{
        flex: 2;
        &__personaldtls{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 20px;
            margin-bottom: 30px;
            .personaldtls-text{
                color: #313131;
                font-size: 13px;
              }
        }
        &__services{
            margin-top: 20px;
        }
        &__tagbox{
           display: inline-flex;
           flex-wrap: wrap;
           .box-tag{
            background-color: #f2f9ff;
                  color: #349afd;
                  text-align: left;
                  font-size: 10px;
                  padding: 2px 10px;
                  margin-right: 10px;
                  margin-top: 5px;
                  margin-bottom: 10px;
                  border: 1px solid #f2f9ff;
                  border-radius: 19px;
           }
        }
    }
}
