.login-box {
  background: #fff;
  box-shadow: 0px 0px 40px #00000014;
  border-radius: 20px;
  padding: 60px 40px;

  .login-box__img {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .login-box__button {
    display: grid;
    .button-items {
      background: #023fac;
      border-radius: 10px;
      padding: 8px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .button-img {
        margin-right: 5px;
      }
    }
  }
  .login-box__divider {
    margin: 30px 0px;
  }
  .login-box__placeholder {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .login-verify__button {
    margin-bottom: 30px;
    border: 1px solid #003fab;
    border-radius: 10px;
    color: #023fac;
    height: 40px;
  }
  .login-verify__button:disabled {
    margin-bottom: 30px;
    border: 1px solid #003fab;
    border-radius: 10px;
    color: #023fac;
    height: 40px;
  }
  .login-msg {
    display: flow-root;
    text-align: center;
  }
  .login-to__register {
    color: #003fab;
    font-weight: 500;
  }
}
