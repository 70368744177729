.sidebar {
  //min-height: 100vh;
  // width: 7rem !important;
  // min-width: 7rem !important;
  // max-width: 7rem !important;
  background-color: #0080fc !important;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  
  
  .ant-menu-vertical {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #0080fc !important;

    .ant-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      align-items: center;
      padding-top: 23px !important;
      padding-bottom: 10px !important;
      padding-left: 18px !important;
      width: 85%;
      color: white;
      height: max-content;

      .ant-menu-title-content {
        transition: none;
        margin-inline-start: 0;
      }
    }
    .ant-menu-item-icon{
      margin-top: 30px;
    }
    
    .ant-menu-submenu {
      margin-top: -10px;
      width: 100% !important;
      padding: 10px !important;
    }

    .ant-menu-item-selected {
      color: #ffa300;
      background-color: #025ab1;
    }

    .ant-menu-item-divider {
      border: 2px solid #3562b7;
      width: 66px;
      margin: auto;
    }

    .ant-menu-sub.ant-menu-inline:not(.ant-menu-hidden) {
      display: block !important;
    }

    .ant-menu-sub.ant-menu-inline.ant-menu-hidden {
      display: none !important;
    }

    .ant-menu-submenu-title {
      padding-left: 35px !important;
      height: 80px;
    }
  }

  .ant-menu-inline:first-child {
    margin-top: 10px;
  }
  .leadgen-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.ant-menu-submenu {
  .ant-menu-vertical{
      margin-left: 7px;
    }

  }
