.viewProfileContainer {
  display: flex;
  height: calc(100vh - 100px);
  justify-content: space-around;
  gap: 10px;
  &__profileContainer {
    flex: 2;
    padding: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    border-radius: 15px;

    .viewProfileHeader {
      padding: 10px 0px;
    }
    .addPostBody {
      display: flex;
      padding: 20px 0px;
      justify-content: space-between;
      font-size: 16px;
      .addPostBodyLeftSection {
        display: flex;
        gap: 15px;
        align-items: center;
        font-weight: 400;
        // color: #c0c0c0;
        color: #535353;
      }
    }
    &--addPost {
      background-color: white;
      border-radius: 15px;
      display: flex;
      padding: 15px 25px 0px 25px;
      flex-direction: column;
      .addPostHeader {
        text-align: left;
        font-size: 20px;
        padding: 10px 0px;
      }
      .addPostBody {
        display: flex;
        padding: 20px 0px;
        justify-content: space-between;
        font-size: 16px;
        .addPostBodyLeftSection {
          display: flex;
          gap: 15px;
          align-items: center;
          font-weight: 400;
          // color: #c0c0c0;
          color: #535353;
        }
      }
    }
  }
  &__middleContainer {
    flex: 0.5;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;
  }
  &__rightContainer {
    flex: 1;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;
  }
}
