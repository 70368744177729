// .ant-radio-button-checked{
//     background: #3F52A3 !important;
//     border-radius: 100px;
// }
.offering{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.offeringcontainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    &-item{
        margin-top: 12px;
        border-radius: 100px;
        padding: 0px;
        border-style: none;
    }
}