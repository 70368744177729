// skeleton css
.cust-skeleton {
  position: static;

  .custom-loading-skeleton {
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
    animation-name: custome-skeleton-loading;
    animation-duration: 1.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    background-size: 400% 100%;
  }

  @keyframes custome-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }
}
