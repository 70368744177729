.chart-container {
    width: 220px;
    height: 220px;
    margin-top: 92px;
    margin-left: 230px;
    
    .pie-container {
      background-color: #fff;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      position: absolute;
      margin-top: -192px;
      margin-left: 30px;
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
  
      h2 {
        color: rgb(0, 128, 252);
      }
    }
  
    .legend-container {
      display: flex;
      justify-content: space-around;
      width: 550px;
      border: 2px solid black;
      height: 50px;
      padding: 12px;
      margin-top: 30px;
      font-size: 17px;
      margin-left: -150px;
      border-radius: 10px;
  
      .legend-item {
        background-color: #D3D3D3;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: 7px;
        margin-right: -15px;
      }
  
      .legend-item--blue {
        background-color: rgb(0, 128, 252);
      }
  
      h4 {
        font-weight: 100;
      }
    }
  }
  @media screen and (max-width: 1520px){
    .chart-container{
        width:170px;
       height: 170px;
       margin-left: 180px;
       .pie-container{
        width: 110px;
        height: 110px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
       margin-top: -142px;
       }
       .legend-container {
           width: 490px;
       }
    }
  }