.companydetails {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.companydetails-container {
  width: 100%;
  margin-top: 20px;

  &__row {
    justify-content: space-evenly;
    &-formitem {
      width: 100%;

      .row-formitem__input {
        height: 40px;
        margin-top: 8px;
      }
      .row-formitem__select {
        height: 40px;
        margin-top: 8px;
        width: 100%;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .createCompany {
    padding: 4px 8px;
    cursor: pointer;
    text-align: right;
    font-size: 12;
    text-align: right;
  }
}
.createCompanyButton {
  padding: 4px 8px;
  cursor: pointer;
  text-align: right;
  font-size: 12;
  svg {
    color: #1677ff;
    margin-right: 4px;
  }
}
