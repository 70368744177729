.dropdown-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-inline: 16px;
  margin: 0px;

  input,
  button {
    width: 32rem;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  .postType {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .uploadImage {
    height: 10rem;
    width: 10rem;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 20px;
  }
  .uploadPDF {
    background-color: #f8f8f8 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border-radius: 8px;
    // margin-top: 20px;
    .ant-upload {
      flex-direction: row;
      background-color: #f8f8f8 !important;
      color: #949494 !important;
      align-items: center;
      height: unset !important;
      width: unset !important;
      gap: 5px;
      border: none !important;
    }
  }
}
.ant-form-item {
  width: 100% !important;
}
.postImage {
  width: 150px;
  height: 150px;
}
.uploadIcon {
  padding: 5px;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;

  svg {
    color: #d9d9d9;
  }
}
.uploadIconSelected {
  border: 1px solid #0081fc87;

  svg {
    color: #0081fc87;
  }
}
