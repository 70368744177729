.photogallery-container {
  margin-top: 10px;
  width: 100%;
  &__imgbox {
    width: 100%;
    overflow: scroll;
    display: flex;
    overflow-x: scroll;
    width: 280px;
    &-img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}
