.wishlist-scroll{
    padding: 20px;
    max-height: 640px;
    overflow-y: scroll;
}

@media screen and (max-width: 1620px)  {
    .wishlist-scroll{
        max-height: 480px;
    }
}