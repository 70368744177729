.header {
  background: white;
  border-radius: 15px;
  padding: 10px 10px;
  margin-bottom: 10px;
  line-height: 0px;
  height: fit-content;
  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    .topnav-placeholder{
      width: 450px;
    }
    .topnav-search__button{
      position: absolute;
      margin-top: -17px;
      margin-left: 13px;
    }
    .header__btn__download,
    .header__btn__download:hover {
      background: #0081fc;
      border-radius: 8px;
      color: #fff !important;
      margin-right: 10px;
    }
    .header__btn__points {
      border: 0;
      margin-right: 10px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #0000000d;
      border-radius: 8px;
      color: #0080fc;
      font-weight: 600;

      small {
        font-weight: 400;
        margin-right: 5px;
      }
    }
    .header__right {
      display: flex;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    margin-top: -3px;
    .header-img {
      cursor: pointer;
    }
    .action-items {
      display: inline-block;
      position: relative;
      text-align: center;
      cursor: pointer !important;
      .items-info {
        z-index: 99999;
        cursor: pointer;
      }
      .header-circle {
        position: absolute;
        top: 5px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    
  }
  .ant-switch-inner {
    background-color: #fca500;
  }
  .ant-switch-checked .ant-switch-inner {
    background-color: #1677ff;
    padding-right: 18px;
  }
  .radioBtns {
    border-radius: 8px;
    border: 1px solid #efefef;
  }
  .topNavBarAbove1920 {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
   justify-content: space-around;
  }

  .radioBtnCustom {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707024;
    border-radius: 7px;
    font-size: 14px;
    padding: 3px 16px;
    margin: 0px 5px;
    cursor: pointer;
  }

  .radioBtnSelected {
    background-color: #1677ff;
    color: white;

    svg {
      margin-right: 5px;
    }

  }
}
.topNavSwitch{
  width: 140px;
 
}
@media screen and (max-width: 1620px)  {
  .header {
    &__items{
      margin-right: -20px;
      .topnav-placeholder{
        width: 320px;
        margin-left: -12px;
      }
      .radioBtnCustom{
        font-size: 12px;
      }
      .topNavSwitch{
        gap: 0px;
       width: 110px;
      }
      .topnav-search__button{
        width: 80px !important;
        margin-left: -10px !important;
      }
    }
    &__actions{
      margin-right: 22px;
    margin-top: -4px;
    .action-items{
      width: 90px !important;
    }
    }
  }
  .topNavBarAbove1920 {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    padding: 10px !important;
    border-radius: 8px;
  }
}
@media screen and (max-width: 1720px){
  .header {
    &__items{
      margin-right: -20px;
      .topnav-placeholder{
        width: 320px;
        margin-left: -12px !important;
      }
      .topnav-search__button{
        margin-left: -10px;
      }
    }}
}
@media screen and (max-width: 1420px){
  .header {
    &__items{
      margin-right: -20px;
      .topnav-placeholder{
        width: 205px;
        margin-left: -12px !important;
      }
      .topnav-search__button{
        width: 70px !important;
        margin-left: -10px !important;
      }
    }
    &__actions{
      margin-top: -3px;
    }
  }
}