.locationcontainer {
  margin-top: 12px;
  &-text {
    font-size: 8px;
    font-weight: 100;
  }
}
.sellercontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &-form {
    margin-bottom: 0px;
    &__space {
      align-items: baseline;
      &--text {
        font-weight: 400;
        font-size: 12px;
      }
      &--subTitle {
        font-size: 14px;
      }
      &--input {
        width: 250px;
      }
      &--tagbox {
        margin-top: 12px;
        border-radius: 100px;
        padding: 0px;
        border-style: none;
        &--radio_selection {
          border-style: none;
          border-radius: 100px !important;
          font-size: 12px;
          padding: 0px 30px 0px 30px;
          height: 20px;
          text-align: center;
          line-height: 1.5714285714285714;
        }
        &--radio_label {
          font-size: 12px;
          margin: 0px;
          padding: 0px;
        }
      }
      &--date {
        width: 250px;
        height: 40px;
      }
      &--label {
        font-size: 12px;
      }
      &--select {
        width: 400px;
      }
    }
    &__tagbox {
      margin-left: 140px;
      &--tag {
        border-radius: 100px;
        margin-left: 0px;
        margin-top: 5px;
        .tag-text {
          color: white;
          font-size: 12px;
        }
      }
    }
  }
}
