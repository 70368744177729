.ant-modal.css-dev-only-do-not-override-1k979oh.savedModal {
  width: 300px !important;
}
.savedModal .ant-modal-content {
  width: 350px;
  height: 280px;
  padding: 24px 50px;
}
.savedModal .ant-modal-footer{
     text-align: center;
}

.savedModal > .ant-modal-content > .ant-modal-footer {
  display: flex;
  justify-content: center;

  > .ant-btn-primary {
    width: 150px;
    height: 40px;
  }
}

.saved {
 
 
  .custom-row.ant-table-row-selected {
    background-color: transparent;
    text-transform: capitalize;
  }

  .ant-btn-primary {
    background: #0081fc;
  }

  .ant-checkbox-inner {
    border-color: #132056;
    width: 20px !important;
    height: 20px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(0.6) translate(0, -50%) !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border: 2px solid #132056;
  }

  .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: transparent !important;
    border: 2px solid #132056 !important;
  }

  .ant-checkbox .ant-checkbox-inner::after {
    border-color: #132056;
    border-top: 0;
    border-inline-start: 0;
    position: absolute;
    top: 15%;
    inset-inline-start: 10%;
    width: 7px;
    height: 0px;
  }

  span.ant-checkbox.ant-checkbox-checked:focus-visible {
    outline: none;
  }

  span.ant-checkbox span.ant-checkbox-inner:hover {
    border-color: #132056;
  }

  span.ant-checkbox.ant-checkbox-checked:focus {
    border: 2px solid #132056;
  }

  .ant-checkbox:hover {
    border-color: #132056;
  }

  .ant-checkbox-inner:hover {
    border-color: #132056;
  }

  .ant-table {
    background: transparent;

    table {
      border-collapse: separate;
      border-spacing: 0 10px;
    }
  }
  
  .ant-table-content {
   
    .ant-table-thead {
      background: #f0f0f0;
      border-radius: 10px;

      .ant-table-cell {
        color: #313131;
        background: #f0f0f0;

        &:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        &:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row-selected > .ant-table-cell {
        background: #fff;
      }

      .ant-table-row {
        background: #ffffff;

        .ant-table-cell {
          border-bottom: 0px;

          &:first-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }

          &:last-child {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }
    }
  }
}
