.registrationcard-box {
  background: #fff;
  box-shadow: 0px 0px 40px #00000014;
  border-radius: 20px;
  margin-top: 10px;
  padding: 30px;
  &__form {
    margin-top: 40px;
    margin-bottom: 30px;
    .form-divider {
      color: #313131;
      margin: 30px 0;
      font-size: 12px;
    }
    .form-item {
      display: grid;
    }
    .form-button {
      background: #023fac;
      border-radius: 10px;
      padding: 8px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .form-img {
      margin-right: 5px;
    }
  }
  .registrationcard-header {
    display: flex;
    .header-text {
      margin-left: 120px;
    }
    .backicon {
      cursor: pointer;
    }
  }
  .login-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    .register-to__login {
      color: #003fab;
      font-weight: 500;
      &:hover {
        color: #1677ff;
      }
    }
  }
}

@media screen and (max-width: 1520px) {
  .registrationcard-box {
    height: 500px;
    margin-top: 20px;
    &__form {
      margin-top: 10px;
    }
    .form-item {
      margin-top: -10px;
    }
  }
}
