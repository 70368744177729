.dropdown-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-inline: 16px;
  margin: 0px;
  input,
  button {
    width: 100%;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  .postType {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .uploadImage {
    height: 10rem;
    width: 10rem;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }
  .ant-upload {
    background-color: #f8f8f8;
    border: none;
    display: flex;
    flex-direction: column;
  }
}
.formButtons {
  position: sticky;
  bottom: 2px;
  right: 0px;
  background-color: white;
}
.selectPlanCard {
  .ant-card-body {
    height: 100%;
  }
}
