.employeeDetails {
  border: 3px solid #0081fc87;
  box-shadow: 0px 5px 8px rgba(0, 129, 252, 0.278431);
  border-radius: 8px;
  flex: 1;
  padding: 10px;
  background: white;
  z-index: 2;
  img {
    object-fit: contain;
    width: 100%;
  }
}

.employeeCard {
  border: 1px solid #808080;
  border-radius: 10px;
  opacity: 1;
  padding: 0px 8px 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  .ant-row .ant-col .ant-row .ant-rate {
    font-size: 15px !important;

    li {
      margin-inline-end: 1px !important;
      color: #ffa300 !important;
    }
  }
}
.employeeAvatar {
  border: 1px solid #70707034;
  padding: 2px;
  font-size: 32px;
}
