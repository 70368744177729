.dashboard__container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .container-left__box {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 10px;

    .card-bottom {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .scroll__y {
    overflow: auto;
    height: 500px;

    &::-webkit-scrollbar {
      width: 0.5em;
    }
  }
  .card__total {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .ant-card:first-child {
      background: #0081fc;

      h4,
      h2 {
        color: #fff !important;
      }
    }
    .ant-card {
      width: 100%;
      background: #fff;
      border: 0;

      .total__heading {
        color: #0081fc;
        font-size: 40px;
        font-weight: 600;
      }
    }
  }
  .card__profile {
    .profile-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .item-yourprofile {
        color: #151515;
      }
      .item-completion {
        color: #0081fc;
      }
    }
    .card-action {
      display: flex;
      gap: 20px;
    }
    .profile-desc {
      color: #767676;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    border: 0;
    button,
    button:hover {
      background: #ffffff;
      border: 1px solid #0081fc;
      border-radius: 8px;
      color: #0081fc !important;
      padding: 7px 20px;
      height: fit-content;
      font-weight: 500;
    }
  }
  .card__notification {
    background: #fff;
    border-radius: 10px;
    border: 0;
    padding: 24px;
    width: 60%;
    .notification-style {
      color: #151515;
      margin-bottom: 5px;
    }
    .scroll__y {
      border: 0px;
      .notification-desc {
        margin-top: 100px;
      }
      .ant-card-body {
        padding: 0;
      }
    }

    .card-notification {
      background: #f4f4f4;
      border-radius: 10px;
      border: 0;
      padding: 24px;
    }
  }
  .card__announcement {
    background: #fff;
    border-radius: 10px;
    border: 0;
    padding: 24px;
    width: 40%;

    .announcement-style {
      color: #151515;
      margin-bottom: 5px;
    }
    .scroll__y {
      border: 0px;
      .announcement-desc {
        margin-top: 100px;
      }
      .ant-card-body {
        padding: 0;
      }
    }
    .card-announcement {
      background-image: url('../../../assets/images/card.png');
      background: linear-gradient(180deg, #accafe 0%, #2f6ddb 100%);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 24px;
    }
  }
  .card__recommended {
    width: 30%;
    background-color: #f4f4f4;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;

    .scroll__y {
      height: 93vh !important;
      .Recommended-desc {
        margin-top: 120px;
      }
    }
    .card__recommended__items {
      background: #fff;
      border-radius: 15px;
      border-left: 10px solid #66b3fd !important;
      border: 0;
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .dashboard__container {
    padding: 0px !important;
  }
  .statscard_container {
    .ant-card-body {
      padding: 20px !important;
    }
  }
}
