.universalSidebar {
  // overflow: auto;
  min-height: 93vh;
  // width: 7rem !important;
  // min-width: 7rem !important;
  // max-width: 7rem !important;
  background-color: #003fab !important;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // align-items: center;
  background-color: #003fab !important;

  .ant-menu-vertical {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    background-color: #003fab !important;

    .ant-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 0 !important;
      padding-inline: 0 !important;
      margin-top: 2rem;
      color: #f4f4f4 !important;
      height: max-content;
      // background-color: red !important;

      .ant-menu-title-content {
        transition: none;
        margin-inline-start: 0;
        
      }
      
    }
    .ant-menu-item-icon {
      margin-top: 30px;
    }

    .ant-menu-item-selected {
      // color: #ffa300;
      background-color: transparent;
      // border: 1px solid;
      // padding: 5px;
    }

    .ant-menu-item-divider {
      border: 2px solid #3562b7;
      width: 66px;
      margin: auto;
    }

    .ant-menu-sub.ant-menu-inline:not(.ant-menu-hidden) {
      display: block !important;
    }

    .ant-menu-sub.ant-menu-inline.ant-menu-hidden {
      display: none !important;
      
    }

    .ant-menu-submenu-title {
      padding-left: 35px !important;
      height: 80px;
      
    }
    
  }

  .ant-menu-item-selected {
    // color: #ffa300;
    background-color: transparent;
  }
  
  .ant-menu-item-divider {
    border: 2px solid #3562b7;
    width: 66px;
    margin: auto;
  }
  
  .ant-menu-sub.ant-menu-inline:not(.ant-menu-hidden) {
    display: block !important;
  }

  .ant-menu-sub.ant-menu-inline.ant-menu-hidden {
    display: none !important;
  }

  .ant-menu-submenu-title {
    padding-left: 35px !important;
    height: 80px;
  }
}
.leadgen-icon {
  width: 25px;
  height: 25px;
}
.crm-icon {
  width: 25px;
  height: 25px;
  margin-left: -33px;
}
.okr-icon {
  width: 25px;
  height: 25px;
  filter: brightness(0) invert(1);
  margin-left: -18px;
}

.fms-icon {
  width: 25px;
  height: 25px;
  filter: brightness(0) invert(1);
    margin-left: -35px;

}

.sidenav-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.ant-menu-submenu {
  .ant-menu-vertical {
    background-color: #003fab !important;
    .ant-menu-item {
      
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 28px;
    }
  }
}
