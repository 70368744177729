.cards-component {
  position: relative;

  .ant-row {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .highlighted-card {
    border: 2px solid #83bbf3 !important;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .next-button {
    float: right;
    width: 120px;
    height: 48px;
    margin-top: 24px;
  }
}

.title {
  text-align: start;
  margin-top: 16px;
}

.postCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .img {
    width: 150px;
    height: 150px;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border: 0.1px solid #535353;
    svg {
      width: 100%;
      height: 100%;
      color: #535353;
    }
  }
}
