.socialAppContainer {
  display: flex;
  height: calc(100vh - 100px);
  justify-content: space-around;
  gap: 10px;
  &__postContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;

    &--addPost {
      background-color: white;
      border-radius: 15px;
      display: flex;
      padding: 15px 25px 0px 25px;
      flex-direction: column;
      .addPostHeader {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0px;
      }
      .addPostBody {
        display: flex;
        padding: 20px 0px;
        justify-content: space-between;
        font-size: 16px;
        .addPostBodyLeftSection {
          display: flex;
          gap: 15px;
          align-items: center;
          font-weight: 400;
          // color: #c0c0c0;
          color: #535353;
        }
      }
    }
  }
  &__middleContainer {
    flex: 1;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;
  }

  &__rightContainer {
    width: 100% !important;
    background-color: #fff;
    padding: 24px;
    border-radius: 10px;
    flex: 0.7;
    width: 350px;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;
    .card-announcement {
      width: 100%;
      .ant-card-body {
        width: 100%;
        padding: 18px;
      }
    }
    .card-announcement:nth-child(odd) {
      background: linear-gradient(180deg, #accafe 0%, #2f6ddb 100%);
    }
    .card-announcement:nth-child(even) {
      background: linear-gradient(180deg, #b4e5ec 0%, #45bed0 100%);
    }
  }
}

.postContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
}
.allPostContainer {
  padding: 36px 50px 40px 40px;
  display: flex;
  margin-top: 20px;
  gap: 15px;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
}
.addPostdivider {
  margin: 0px;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}
