.companiesList {
  .ant-card-body {
    .ant-table-wrapper {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .ant-table {
            .ant-table-container {
              .ant-table-content {
                table {
                  border-spacing: 0 10px;
                  .ant-table-thead {
                    border-radius: 10px;
                    font-size: 12px;
                    background-color: #f0f0f0;
                    tr > .ant-table-cell {
                      padding: 8px !important;
                      border-bottom: 1px solid #ffffff;
                      color: #313131;
                    }
                    tr {
                      .ant-table-cell:first-child {
                        border-bottom-left-radius: 10px;
                      }
                      .ant-table-cell:last-child {
                        border-bottom-right-radius: 10px;
                      }
                    }
                    tr > .ant-table-cell::before {
                      content: none;
                    }
                  }
                  .ant-table-tbody {
                    margin-top: 10px;
                    border-radius: 10px;
                    font-size: 12px;
                    tr > .ant-table-cell {
                      padding: 8px !important;
                      border-bottom: 0px !important;
                      color: #313131;
                    }
                    tr > .ant-table-cell::before {
                      content: none;
                    }
                    tr {
                      background-color: #f8f8f8;
                      .ant-table-cell:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .ant-table-cell:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.personalDtlsSection {
  // padding-top: 20px;
  // padding-bottom: 10px;
  // padding-left: 10px;
  background-color: #f8f8f8;

  display: flex;
  flex-direction: row;
  gap: 10px;
  &-card {
    flex: 1;
    height: 100%;
    overflow: scroll;
   
    .card__title {
      color: #0081fc;
      font-size: 24px;
      margin-bottom: 0px;
      &-pointer {
        cursor: pointer;
        vertical-align: middle;
      }
      &-username {
        font-size: 16px;
        vertical-align: middle;
        font-weight: 600;
      }
    }
    .prospectImage {
      text-align: center;
    }
    .card__heading {
      font-weight: 600;
    }
  }
  .companiesList {
    flex: 2;
     height: 100%;
    overflow: scroll;
  }
}
