.layout {
    min-height: 90vh;
  }
  
  .content {
    padding: 16px;
    background: #fff;
  }
  
  .section {
    margin-bottom: 32px;
    text-align: left;
    font-size: 16px;
  }

  .director{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

 
  }
  .contact {
    display: inline-block;
    padding-left: 0.5rem;
  }

  .text{
    color: #349AFD;
    cursor:pointer;
}
  
  