.carousel {
  position: relative;
  width: 80%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
}

.carousel img {
  width: 100%;
  height: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.carousel-control {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  border-radius: 60%;
  transform: translateY(-50%);
  background-color: rgba(196, 192, 192, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  border: none;
  outline: none;
}

.carousel-control.prev {
  left: 10px;
}

.carousel-control.next {
  right: 10px;
}
.carousel-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(137, 134, 134, 0.3);
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  outline: none;
}

.dot.active {
  background-color: rgba(92, 103, 139, 0.8);
}
