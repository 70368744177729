.container {
  display: flex;
  width: 100%;
  height: 100vh;
  gap: 12px;
  overflow: hidden;

  .property_container {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .company_container {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .connect_container {
    width: 33%;
  }
}
