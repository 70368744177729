.ant-layout {
  background: #f8f8f8;
}
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background-color: #a7a7a7;
}
.mandatory-star {
  color: #ff2424;
  margin-right: 3px;
}
::placeholder {
  color: #7c7c7c;
  font-size: 12px;
}
.formControl,
.formControl:hover,
.formControl:focus,
.formControl:focus-within,
.ant-input {
  box-shadow: none !important;
  font-size: 12px;
  outline: 0;
  padding: 0.6rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  // background: #f7faff !important;
  // border-radius: 8px;
  // border: 0px solid #ced4da;
}
.ant-form-item-explain-error {
  font-size: 11px;
}

// -----common global css--------
.d-flex {
  display: flex;
}
.d-column {
  flex-direction: column;
}
.jc-center {
  justify-content: center;
}
.jc-end {
  justify-content: flex-end;
}
.jc-between {
  justify-content: space-between;
}
.jc-around {
  justify-content: space-around;
}
.a-start {
  align-items: start;
}

.a-center {
  align-items: center;
}

.a-end {
  align-items: end;
}

.a-between {
  align-items: space-between;
}
.a-around {
  align-items: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
.g-5 {
  gap: 5px;
}
.g-10 {
  gap: 10px;
}
.g-15 {
  gap: 15px;
}
.g-20 {
  gap: 20px;
}

.font12 {
  font-size: 12px;
}
.font10 {
  font-size: 10px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font400 {
  font-weight: 400;
}
.font500 {
  font-weight: 500;
}
.font600 {
  font-weight: 600;
}
.font700 {
  font-weight: 700;
}

.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-20 {
  padding-right: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.fontDark {
  color: rgba(0, 0, 0);
}
.fontLight {
  color: rgba(0, 0, 0, 0.88);
}
.fontExtraLight {
  color: #949494;
}
.fontLight-2 {
  color: #808080;
}

.fontError {
  color: red;
}
.fontBlue {
  color: #1677ff;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 500%;
}
.text-center {
  text-align: center;
}
.position-relative {
  position: relative;
}
.anchor-button {
  color: #1677ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 18px;
  border-color: white;
}
.anchor-button:hover {
  color: #69b1ff;
  border-color: #ffff !important;
  background: #ffffff !important;
}
.text-underline {
  text-decoration: underline;
}
.flex-1 {
  flex: 1;
}
.cursorPointer {
  cursor: pointer;
}

.border-none {
  border: none !important;
}
.border-light-2 {
  border: 1px solid rgba(112, 112, 112, 0.2745098039) !important;
}
.border-radius-8 {
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}
