.userPersonalDtls,
.userCompanyDtls,
.personalDtlsSection {
  &__companyDetails {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }

  &__personalDetails {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
  .editDetails-div {
    width: auto !important;
  }
  .editSaveBtn {
    line-height: 0.571429;
    height: 32px;
    padding: 12px 0px;
    border-radius: 10px;
    width: 100px;
    font-family: 'Poppins' !important;
  }
  .formItemLabel {
    text-align: justify;
    .form-suffix {
      margin-left: 0.4rem;
    }
    .input-box {
      font-weight: 500;
      color: #132056;
      font-size: 18px;
    }
    .input-details {
      font-size: 18px;
    }
  }
  .formItemLabel .ant-form-item-label label {
    color: #767676 !important;
  }

  .ant-space-item .profileTxt {
    text-align: left;
  }
}

.userCompanyDtls {
  .formItemLabel .ant-form-item-label label {
    color: #767676 !important;
  }
}

.personalDtlsSection {
  .ant-card-body {
    padding: 20px;
    height: 100%;
    overflow: scroll;
  }
}
.personalDtlsSection__personalDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .personalDetails-title {
    align-self: center;
    font-size: 20px;
  }
}
.personalDetails-space {
  margin-top: 5%;
  text-align: left;
  // margin-bottom: 43px;
  .personalDetails-message {
    color: #b4b4b4;
    font-style: italic;
    font-size: 14px;
  }
  .item-center {
    text-align: center;
  }
}
.personalDtlsSection__companyDetails {
  display: block;
  .companyDetails-heading {
    text-align: left;
  }
  .companyDetails-label {
    text-align: justify;
    .form-placeholder {
      font-size: 18px;
    }
    .verify-icon {
      margin-left: 0.4rem;
    }
    .company-placeholder {
      font-weight: 500;
      color: #132056;
      font-size: 18px;
    }
  }
  .companyDetails-key {
    font: normal normal normal 14px/21px Poppins;
    color: #676767;
    font-size: 14px !important;
    text-align: left;
    display: block;
  }
}

.userPersonalDtls__formValues {
  margin-bottom: 19px !important;
}

.personalDtlsSection .formItemLabel {
  margin-bottom: 11px !important; 
 
}
.personalDtlsSection__companyDetails .companyDetails-label {
  margin-bottom: 5px !important;
}
.personalDtlsSection__companyDetails .companyDetails-label .ant-form-item-required{
  font-size: 14px !important;
}
.aadhar-input::-webkit-outer-spin-button,
.aadhar-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.personalDtlsSection .formItemLabel .ant-form-item-required{
  font-size: 14px !important;
}