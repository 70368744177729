.generic_post_content {
  flex: 1;
  display: flex;
  gap: 40px;
}

.generic_post_images {
  // flex: 1;
  // display: flex;
  // flex-wrap: wrap;
  // gap: 10px;
  z-index: 10;
  .report-carousel-image {
    margin-top: -60px;
    border-radius: 20px;
    height: 365px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    width: 365px;
    border: 1px solid #f8f8f8;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .postImageTitle {
      font-size: 24px;
      color: white;
      text-align: center;
      text-decoration: underline;
    }
    .postImageFooter {
      font-size: 14px;
      color: white;
      background-color: #16114d;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
}
.cta {
  display: flex;
  align-items: center;
}
.postBody {
  list-style-position: inside;
}
