.selectedPropertyContainer {
  display: flex;
  border-radius: 8px;
  border: 1px solid #0081fc87;
  .selectedPropertyImage {
    width: 30%;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .selectedPropertyDetails {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
.similarPropertyContainer {
  border: 1px solid #70707046;
  border-radius: 8px;
  flex: 1;
  padding: 10px;
}

.companyCard {
  border: 3px solid #0081fc87;
  box-shadow: 0px 5px 8px #0081fc47;
  border-radius: 10px;
  opacity: 1;
  padding: 0px 8px 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 215px;
  position: relative;
  background-color: white;
  z-index: 1;

  // &::after {
  //   content: '- - - -';
  //   color: blue;
  //   position: absolute;
  //   top: 50%;
  //   transform: translate(-100%, -50%);
  //   z-index: -1;
  // }
  .ant-row .ant-col .ant-row .ant-rate {
    font-size: 15px !important;

    li {
      margin-inline-end: 1px !important;
      color: #ffa300 !important;
    }
  }
}
.connectorLine {
  position: absolute;
  top: 50%;
  transform: translate(-30%, -50%);
  color: #0081fc87;
  width: 150%;
  letter-spacing: -2.2px;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.inside_row {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.bottomRow {
  padding: 10px 0px;
  border-top: 1px solid #f0f0f0;
}
.ant-rate-star.ant-rate-star-full,
.ant-rate-star.ant-rate-star-zero,
.ant-rate-star.ant-rate-star-half.ant-rate-star-active {
  transition: transform 0s;
}

.ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
  transform: scale(0.91);
}

.ant-rate-star.ant-rate-star-full,
.ant-rate-star.ant-rate-star-half {
  color: #ffc748 !important;
}
.ant-rate-star.ant-rate-star-full:hover {
  transform: scale(0.91);
}

.ant-rate-star.ant-rate-star-zero:hover {
  transform: scale(0.91);
}
.companyAvatar {
  border: 1px solid #70707034;
  padding: 2px;
  width: 50px;
  height: 50px;
}
.similarCompanyScrollbar::-webkit-scrollbar {
  width: 5px;
  max-height: 15px !important;
}

.similarCompanyScrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.8);
  border-radius: 10px;
  max-height: 30px;
}

.similarCompanyScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.8);
}

.similarCompanyScrollbar::-webkit-scrollbar-track {
  background: transparent;
}


.similarCompanyScrollbar::-webkit-scrollbar-corner {
  background: transparent;
}