.container {
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.card-container {
  position: absolute;
  top: 80%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}
.page-container {
  display: flex;
  flex-direction: row;
  height: 93vh;

  .page-container__left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .left-item {
      margin-left: 80px;
      margin-top: 40px;

      .left-item__title {
        margin-bottom: 10px;
        color: #132056;
      }
      .left-item__slogan {
        margin-bottom: 10px;
        margin-top: 0px;
        color: #707070;
        font-weight: 400;
      }
      .left-item__desc {
        margin: 0;
        color: #b4b4b4;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .page-container__right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .right-card {
      width: 500px;
      padding: 0;
      border: 0;
    }
  }
}
