.commonrow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &-form {
    margin-bottom: 0px;
    &__space {
      align-items: baseline;
      flex-wrap: wrap;
      &--text {
        font-size: 12px;
        font-weight: 400;
      }
      &--date {
        width: 250px;
      }
      &--locationtext {
        font-size: 12px;
      }
      &--select {
        width: 400px;
      }
    }
    &__location {
      // margin-left: 140px;
      max-height: 100px;
      max-width: 700px;
      overflow: scroll;
      &--tag {
        border-radius: 100px;
        margin-left: 0px;
        margin-top: 5px;
        .tag-text {
          color: white;
          font-size: 12px;
        }
      }
    }
    .open_to_broker {
      font-weight: 700;
    }
  }
}
.ellipsis {
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
}
.closed-icon {
  position: absolute;
  top: 30%;
}
.ant-form-item-required {
  font-size: 12px !important;
}

.footerDivider {
  margin: 10px 0px 0px 0px;
}

.iconButton {
  padding-top: 15px;
}

.footerInput {
  display: flex;
  gap: 10px;
  padding-top: 15px;
  align-items: center;
  .ant-input-affix-wrapper {
    height: 30px;
    background-color: #f8f8f8;
    border: none;
    border-radius: 10px;
    flex: 2;
    box-shadow: none;
  }
}
