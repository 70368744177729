.barGraphContainer{
    padding: 20px;
    width: 680px;
    &-div{
        background-color:rgb(0, 128, 252);
        width: 250px;
        height: 43px;
        margin-left: -19px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        &__text{
            color: #fff;
            text-align: center;
            font-weight: 200;
            font-size: 15px;
            padding: 9px;
        }
    }
    &-graph{
        margin-top: 40px;
    }
  }
  @media screen and (max-width: 1520px){
    .barGraphContainer{
        width: 550px;
    }
  }